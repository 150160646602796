import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import Portfolio from './components/portfolio'
import Index from './components/index'

const SetPageTitle = () => {
  const location = useLocation();
  
  useEffect(() => {
    const path = location.pathname;

    if (path === '/') {
      document.title = 'Bernar';
    } else if (path === '/portfolio') {
      document.title = 'portfolio';
    } else {
      document.title = 'Bernar';
    }
  }, [location]);

  return null;
};

export default function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/" element={<Index />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}